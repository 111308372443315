<template>
  <div
    class="navigation"
    v-bind:class="navigation.isNavigationOpened ? 'opened' : ''"
  >
    <a class="navigation-toggle" v-on:click="toggleNavigation()">
      <span
        v-bind:class="
          navigation.isNavigationOpened ? 'icon-close' : 'icon-menu'
        "
      ></span>
    </a>

    <div class="navigation-links">
      <div class="navigation-links-text">
        <!-- router-link
          :to="{ name: 'Home' }"
          class="router-link-home visible-lg-flex"
          @click.native="closeNavigation()"
        >
          Home
        </router-link -->

        <router-link :to="{ name: 'Shows' }" @click.native="closeNavigation()">
          Shows
        </router-link>
        <router-link
          :to="{ name: 'Calendar' }"
          @click.native="closeNavigation()"
        >
          Program
        </router-link>
        <router-link
          :to="{ name: 'Archive' }"
          @click.native="closeNavigation()"
        >
          Archive
        </router-link>
        <router-link
          :to="{ name: 'Search' }"
          @click.native="closeNavigation()"
          class="hidden-lg"
        >
          Search
        </router-link>
        <router-link
          :to="{ name: 'Playlist', params: { uuid: '' } }"
          @click.native="closeNavigation()"
        >
          Playlist
        </router-link>
      </div>
      <section class="navigation-links-social-media">
        <a
          href="https://soundcloud.com/radiopunctum"
          target="_blank"
          title="Soundcloud"
          rel="noreferrer"
          ><span class="icon-soundcloud"></span
        ></a>
        <a
          href="https://www.facebook.com/radiopunctum"
          target="_blank"
          title="Facebook"
          rel="noreferrer"
          ><span class="icon-facebook"></span
        ></a>
        <a
          href="https://www.instagram.com/radio_punctum/"
          target="_blank"
          title="Instagram"
          rel="noreferrer"
          ><span class="icon-instagram"></span
        ></a>
      </section>
    </div>
    <SearchNavigation class="visible-md" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SearchNavigation from "@/components/Search/SearchNavigation.vue";

export default {
  name: "Navigation",
  components: {
    SearchNavigation
  },
  computed: {
    ...mapState({
      navigation: state => state.navigation
    })
  },
  methods: {
    ...mapActions("navigation", ["toggleNavigation", "closeNavigation"])
  }
};
</script>

<style scoped lang="scss">
@import "../variables";
.navigation {
  display: flex;
  height: $navigation-height-xs;
  flex: 1;
  padding-right: 10px;

  @media (max-width: $screen-xxxs-min) {
    height: $navigation-height-xxxs;
  }

  @media (min-width: $screen-sm-min) {
    justify-content: flex-end;
    height: $navigation-height;
  }

  .navigation-links {
    display: none;
    flex-direction: row;
    font-family: $title-font;

    @media (min-width: $screen-lg-min) {
      display: flex;
      padding-right: $base-unit * 15;
    }

    @media (min-width: $screen-lg-min) and (max-width: $screen-xl-min) {
      .router-link-additional {
        display: none;
      }
    }

    .navigation-links-text {
      display: flex;
      padding: 0 $base-unit * 3;

      @media (min-width: $screen-md-min) {
        border-left: 1.4px solid $navigation-border-color;
      }
    }

    a {
      display: flex;
      align-items: center;
      padding: 0 $base-unit * 3;
      font-size: $navigation-font-size;
      text-decoration: none;
      text-transform: uppercase;
      color: $txt-color;
      font-weight: 100;

      @media (min-width: $screen-sm-min) {
        height: $navigation-height;
      }

      @media (min-width: $screen-xxl-min) {
        padding: 0 $base-unit * 5;
      }

      &:hover {
        color: $anchor-color;
      }

      &.router-link-active {
        font-weight: bold;

        &.router-link-home {
          font-weight: 100;
        }
      }

      &.router-link-exact-active {
        font-weight: bold;
      }
    }

    .audio-player-broadcast-toggle {
      cursor: pointer;
    }
  }

  .navigation-links-social-media {
    display: none;
    justify-content: space-around;
    padding: 0 $base-unit;

    a {
      padding: $base-unit * 4 $base-unit * 2;
    }
  }

  &.opened {
    .navigation-links {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-top: $base-unit * 10;
      flex-direction: column;
      justify-content: space-between;
      background: $navigation-bg;
      z-index: 25;
    }

    .navigation-links-text {
      flex-direction: column;

      a {
        justify-content: flex-end;
        font-size: $navigation-font-size-xs;
        padding: $spacing-default/3 0;

        @media (min-width: $screen-sm-min) {
          padding: 0 $spacing-default;
        }
      }
    }

    .navigation-toggle {
      background: none;
    }

    .navigation-links-social-media {
      display: flex;
    }
  }
}

.navigation-toggle {
  display: flex;
  align-items: center;
  padding: $spacing-default/2;
  color: $color-secondary;
  font-size: 42px;
  line-height: 1.4;
  z-index: 26;
  text-decoration: none;
  cursor: pointer;

  @media (min-width: $screen-lg-min) {
    display: none;
  }

  @media (max-width: $screen-md-max) {
    font-size: 24px;
  }
}
</style>
